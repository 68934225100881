import { Typography } from '@material-ui/core'
import React from 'react'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
function Linkdeactivationpage(props) {
    return (
        <div style={{width:"100vw",height:"100vh",display:"inline-flex",flexDirection:"column", justifyContent:"center",flexWrap:"wrap", alignItems:"center"}}>
            <div style={{margin:"auto",backgroundColor:" #FF9900",borderRadius:"10px",padding:"25px",display:"flex",flexDirection:"column",justifyContent:"center"}}>
            <CancelPresentationIcon style={{placeSelf:"center",color:"white"}} />
            <Typography style={{color:"white",margin:"3px",fontSize:"20px",}}>{props.message}</Typography>
            </div>
        </div>
    )
}

export default Linkdeactivationpage
