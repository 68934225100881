import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Dashboard from "./screens/Dashboard";
import { styled } from "@mui/material/styles";
import Tab3 from "./screens/Tab3";
import Topbar from "./Topbar";
import backgroundImage from "../images/d1.png";
import Tabs from "@mui/material/Tabs";
import Appbar from "../Appbar";
import axios from 'axios'
import { dbname, arangodbservice, } from "../Components/urlconst";


const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    marginBottom: "19px",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 50,
    width: "100%",
    backgroundColor: "#00205C",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1.5),
    // color: 'rgba(255, 255, 255, 0.7)',
    "&.Mui-selected": {
      color: "#000000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function Homepagetabs() {
  const [value, setValue] = React.useState("1");
  const [v, setV] = React.useState("");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(()=>{
    let params = new URLSearchParams(window.location.search);
    // if(params.get("metadata_id")!==""){
    sessionStorage.setItem("metadata", params.get("metadata_id"));
    sessionStorage.setItem("token", params.get("token"));
    // }
    console.log(sessionStorage.getItem("metadataId"))
    var datas = {
        db_name: dbname,
        entity: "projectvstools",
        filter: `projectvstools.metadataid =='${sessionStorage.getItem("metadata")}'`,
        return_fields: "projectvstools",
    };

    var config = {
        method: "post",
        url:`${arangodbservice}/read_documents`,
        header: { "content-Type": "application/json" },
        data: datas,
    };

    axios(config)
        .then((res) => {
            sessionStorage.setItem("clientid", res.data.result[0].clientid);
            sessionStorage.setItem("projectid", res.data.result[0].projectid);
            var data1 = {
              db_name: process.env.REACT_APP_DB_NAME,
              entity: "clients",
              filter: `clients._id =='${sessionStorage.getItem("clientid")}'`,
              return_fields: "clients",
            };
            var config1 = {
              method: "post",
              url:`${arangodbservice}/read_documents`,
              header: { "content-Type": "application/json" },
              data: data1,
            };
            axios(config1).then((res) => {
              console.log(res.data.result[0].clientname);
              sessionStorage.setItem("clientname",res.data.result[0].clientname );
              setV("1")

            });
            var data2 = {
              db_name: process.env.REACT_APP_DB_NAME,
              entity: "projects",
              filter: `projects._id =='${sessionStorage.getItem("projectid")}'`,
              return_fields: "projects",
            };
            var config2 = {
              method: "post",
              url:`${arangodbservice}/read_documents`,
              header: { "content-Type": "application/json" },
              data: data2,
            };
            axios(config2)
            .then((res) => {
              console.log(res.data.result[0].projectname);
              sessionStorage.setItem("projectname",res.data.result[0].projectname );
              setV("2")

            })
            .catch((err)=>{
          alert("There is an error")
            })

        })
        .catch((err) => {
            console.error(err);
        });
  },[])

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImage})`,
        minWidth: "100vw",
        minHeight: "100vh",
        maxWidth: "90%",
        maxHeight: "70%",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        backgroundRepeat: "no-repeat",
        backgroundRepeatX: "no-repeat",
        backgroundRepeatY: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* <Appbar/> */}
      <Topbar />

      <TabContext value={value}>
        <div style={{ marginLeft: "30px",display:"inline-flex" }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="styled tabs example"
          >
            <StyledTab label="Dashboard" value="1" />

            <StyledTab label="Leader Board" value="2" />
          </StyledTabs>
        </div>

        <TabPanel value="1" style={{ padding: "0px" }}>
          <Dashboard />
        </TabPanel>
        <TabPanel value="2" style={{ padding: "0px" }}>
         <div style={{marginLeft:"35px"}}> <Tab3 /></div>
        </TabPanel>
      </TabContext>
    </div>
  );
}

  