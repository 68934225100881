import * as React from "react";

import Paper from "@mui/material/Paper";

import IconButton from "@mui/material/IconButton";

import axios from "axios";

import NativeSelect from "@mui/material/NativeSelect";

import { CgClose } from "react-icons/cg";
import { TextInput, Button } from "qdm-component-library";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { dbname, arangodbservice, posturl, geturl } from "../urlconst";
import InputBase from "@mui/material/InputBase";

import { styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      position: "relative",
      borderRadius: "0px 5px 5px 0px",
      height: "14px",
      lineHeight: "9px",
      border: "1px solid #cccaca ",
      textAlign: "Right",
      fontSize: 12,
      fontFamily: ["Arial"].join(","),
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0rem rgba(0,123,255,.25)",
      },
    },
  }));

function Add(props) {


    const [adddialog, setadddialog] = React.useState(false);
    const [longURLforadd, setLongURLforadd] = React.useState();
    const [clientKeyforadd, setClientKeyforadd] = React.useState();
    const [clientIdforAdd, setClientIdforAdd] = React.useState(sessionStorage.getItem("clientname"));
    const [domainforShortutlforAdd, setDomainForShortUrlforAdd] = React.useState(
        `${window.location.origin}/url/`
    );
    const [projectidforadd, setProjectidforadd] = React.useState(sessionStorage.getItem("projectname"))
    const [validityforadd, setValidityforadd] = React.useState();
    const [dropdownforadd, setDropdownforadd] = React.useState("");



   

    
    const openaddfunc = (v) => {
        setadddialog(true);
    };

    const closeaddFunc = () => {
        setadddialog(false);
    };


    const handleAddfunc = (e) => {
        e.preventDefault();
        console.log(
            dropdownforadd,
            validityforadd,
            longURLforadd,
            clientKeyforadd,
            projectidforadd
        );

        var config = {
            method: "post",
            url: `${posturl}/shortenUrl`,
            headers: {
                "Content-Type": "applicatiom/json",
            },

            data: {
                url: `${longURLforadd}`,
                clientKey: `${clientKeyforadd}`,
                ttl: Number(validityforadd),
                ttlTypes: [`${dropdownforadd}`],
                domainForShortUrl: `${domainforShortutlforAdd}`,
                clientId:sessionStorage.getItem("clientid") ,
                metadataid:sessionStorage.getItem("metadata"),
                projectid:sessionStorage.getItem("projectid"),
                status: true
            },
        };

        axios(config).then((res) => {
            console.log(res.data.shortenUrl);
            setadddialog(!adddialog);
            setClientKeyforadd("");
            setDropdownforadd("");
            setValidityforadd("");
            setLongURLforadd("");
            setClientIdforAdd("");
            setDomainForShortUrlforAdd("");
            props.onClose("success")
        })
        .catch((err)=>{
            props.onClose("error")

        })
    };


    const handleChangeforadd = (event) => {
        setDropdownforadd(event.target.value);
    };

    return (
        <div>
            <Paper>
                <form onSubmit={(e) => handleAddfunc(e)}>
                    <IconButton
                        onClick={props.onClose}

                        style={{
                            marginLeft: "488px",
                            marginBottom: "-28px",
                        }}
                    >
                        <CgClose
                            style={{
                                color: "black",
                            }}
                        />
                    </IconButton>
                    <DialogContent>
                        <Stack direction="column" spacing={2}>
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row">
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        Paste your long URL here:
                                    </DialogContentText>{" "}
                                    <p style={{ color: "red", margin: "-6px 0px 0px 0px" }}>
                                        *
                                    </p>
                                </Stack>
                                <TextInput
                                    placeholder="paste your url"
                                    style={{
                                        borderColor: "#cccaca",
                                        borderRadius: "5px",
                                        height: "25px",
                                        width: "490px",
                                        align: "center",
                                        marginBottom: "8px",
                                        background: "#FFFFFF",
                                        fontFamily: "Arial",
                                        fontStyle: "normal",
                                        fontWeight: "200",
                                        fontSize: "12px",
                                        lineHeight: "17px",
                                        color: "#848181",
                                    }}
                                    required
                                    value={longURLforadd}
                                    onChange={(e) => {
                                        setLongURLforadd(e.target.value);
                                    }}
                                    hoverColor="#0071F2"
                                    maxLength={524288}
                                />
                                {/* <FaStarOfLife
                    style={{
                      position: "absolute",
                      marginTop: "-3px",
                      marginLeft: "140px",
                      color: "red",
                      width: "5px",
                    }}
                  /> */}
                            </Stack>

                            <Stack direction="row" spacing={5}>
                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        Client Name
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-25px 0px 0px 69px" }}>
                                        *
                                    </p>
                                    <TextInput
                                    readOnly
                                        type="text"
                                        placeholder="Numbers only(ex:123)"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px",
                                            marginBottom: "8px",
                                            height: "25px",
                                            width: "220px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                        }}
                                        required
                                        hoverColor="#0071F2"
                                        onChange={(e) => {
                                            setClientIdforAdd(e.target.value);
                                        }}
                                        value={clientIdforAdd}
                                    />
                                    {/* <FaStarOfLife
                      style={{
                        position: "absolute",
                        marginTop: "-4px",
                        marginLeft: "46px",
                        color: "red",
                        width: "4px",
                      }}
                    /> */}
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        DomainForShortUrl
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-26px 0px 0px 113px" }}>
                                        *
                                    </p>
                                    <TextInput
                                        readOnly
                                        defaultValue={domainforShortutlforAdd}
                                        placeholder="domainForShortUrl"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px",
                                            height: "25px",
                                            width: "230px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                        }}
                                        required
                                        hoverColor="#0071F2"
                                        // onChange={(e) => {
                                        //   setDomainForShortUrlforAdd(e.target.value);
                                        // }}
                                        value={domainforShortutlforAdd}
                                    />
                                    {/* <FaStarOfLife
                      style={{
                        position: "absolute",
                        marginTop: "-4px",
                        marginLeft: "106px",
                        color: "red",
                        width: "4px",
                      }}
                    /> */}
                                </Stack>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        Client Key
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-25px 0px 0px 59px" }}>
                                        *
                                    </p>

                                    <TextInput
                                        type="Number"
                                        placeholder="Numbers only(ex:123)"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px",
                                            height: "25px",
                                            width: "190px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                            marginTop: "-7px",
                                            marginBottom: "-14px",
                                        }}
                                        required
                                        hoverColor="#0071F2"
                                        onChange={(e) => {
                                            setClientKeyforadd(e.target.value);
                                        }}
                                        value={clientKeyforadd}
                                    />
                                    {/* <FaStarOfLife
                      style={{
                        position: "absolute",
                        marginTop: "-3px",
                        marginLeft: "56px",
                        color: "red",
                        width: "4px",
                      }}
                    /> */}
                                </Stack>

                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        Project Name
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-25px 0px 0px 76px" }}>
                                        *
                                    </p>
                                    <TextInput
                                        readOnly
                                        type="text"
                                        placeholder="Numbers only(ex:123)"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px",
                                            height: "25px",
                                            width: "150px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                            marginTop: "-7px",
                                            marginBottom: "-14px",
                                        }}
                                        required
                                        hoverColor="#0071F2"
                                        onChange={(e) => {
                                            setProjectidforadd(e.target.value);
                                        }}
                                        value={projectidforadd}
                                    />
                                    {/* <FaStarOfLife
                      style={{
                        position: "absolute",
                        marginTop: "-3px",
                        marginLeft: "53px",
                        color: "red",
                        width: "4px",
                      }}
                    /> */}
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                            background: "#FFFFFF",
                                        }}
                                    >
                                        Validity Period
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-25px 0px 0px 85px" }}>
                                        *
                                    </p>
                                    <TextInput
                                        type="Number"
                                        placeholder="Numbers only(ex:123)"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px 0px 0px 5px",
                                            height: "25.1px",
                                            width: "73px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                        }}
                                        required
                                        hoverColor="#0071F2"
                                        onChange={(e) => {
                                            setValidityforadd(e.target.value);
                                        }}
                                        value={validityforadd}
                                    />
                                    {/* <FaStarOfLife
                      style={{
                        position: "absolute",
                        marginTop: "-3px",
                        marginLeft: "81px",
                        color: "red",
                        width: "4px",
                      }}
                    /> */}
                                    <NativeSelect
                                        placeholder="select"
                                        label="select"
                                        style={{
                                            display: "flex",
                                            borderColor: "#cccaca",
                                            marginTop: "-25px",
                                            borderRadius: "25px",
                                            marginLeft: "65px",
                                            width: "69px",
                                            align: "center",
                                            // boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.25)",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                        }}
                                        id="demo-customized-select-native"
                                        value={dropdownforadd}
                                        onChange={handleChangeforadd}
                                        input={<BootstrapInput />}
                                    >
                                        {/* <option
                        style={{
                          color: "#00205C",
                          fontFamily:"Arial"
                        }}
                      >
                        Select
                      </option> */}
                                        <option
                                            value={"minutes"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            Minute
                                        </option>
                                        <option
                                            value={"hour"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            Hour
                                        </option>
                                        <option
                                            value={"days"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            Days
                                        </option>
                                        <option
                                            value={"months"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            Months
                                        </option>
                                        <option
                                            value={"years"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                            required
                                        >
                                            Years
                                        </option>
                                    </NativeSelect>
                                </Stack>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            // onClick={handleAddfunc}
                            style={{
                                borderRadius: "15px",
                                marginRight: "227px",
                                marginLeft: "-40px",
                                width: "85px",
                                marginBottom: "25px",
                                marginTop: "5px",
                                height: "25px",
                                fontSize: "14px",
                                lineHeight: "5px",
                                backgroundColor: "#00205c",
                            }}
                            type="submit"
                        >
                            Shorten
                        </Button>
                    </DialogActions>
                </form>
            </Paper>

        </div>
    )
}

export default Add
