import React from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function Alerts(props) {
  // console.log("propss", props);
  return (
    <div>
      <Snackbar
        open={props.open}
        autoHideDuration={props.autoHideDuration}
        onClose={props.onClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={props.onClose}
          severity={props.severity}
          sx={{ width: "100%" }}
        >
          {props.alertvalue}
        </Alert>
      </Snackbar>
    </div>
  );
}

Alerts.defaultProps = {
  open: "",
  autoHideDuration: 3000,
  onClose: null,
  severity: "",
  alertvalue: "",
};
export default Alerts;
