import React from "react";
import { Typography } from "@mui/material";

function Appbar() {
  return (
    <div>
      <div style={{ width: "auto", height: "auto" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {/* <Typography style={{color:"#0a78fa",fontSize:"20px"}}>ATP</Typography> */}
          <div
            style={{
              display: "inline-flex",
              flexDirection: "column",
              marginTop: "2px",
            }}
          >
            <Typography
              style={{
                // backgroundColor:"#0a78fa",
                // color:"white",
                padding: "1px 2px 1px 1px",
                fontSize: "11px",
                width: "70px",
              }}
            >
              Client Name
            </Typography>
            <Typography style={{ fontSize: "13px" }}>
              {sessionStorage.getItem("clientname")}
            </Typography>
          </div>
          <div
            style={{
              display: "inline-flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                // backgroundColor:"#0a78fa",
                // color:"white",
                padding: "1px 2px 1px 1px",
                fontSize: "11px",
                width: "85px",
              }}
            >
              Project Name
            </Typography>
            <Typography style={{ fontSize: "13px" }}>
              {sessionStorage.getItem("projectname")}
            </Typography>
          </div>

          <div
            style={{
              display: "inline-flex",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{
                // backgroundColor:"#0a78fa",
                // color:"white",
                padding: "1px 2px 1px 1px",
                fontSize: "11px",
                width: "85px",
              }}
            >
              Database Name
            </Typography>
            <Typography style={{ fontSize: "13px" }}>
              {process.env.REACT_APP_DB_NAME}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Appbar;
