import axios from 'axios';
import React from 'react'
import { posturl } from '../urlconst';
import Linkdeactivationpage from './Linkdeactivationpage';





function Longutldisplay(props) {
  {console.log("props",props)}

  const [activestatus, setActivestatus] = React.useState(false)

  //   console.log(window.location.href.slice(22,window.location.href.length))
  //   var config = {
  //     method: 'post',
  //     url: 
  //     "https://apinifi.dev.ainqaplatform.in/api/v1/urlShortener/url",
  //     headers: { 
  //       'Content-Type': 'text/plain'
  //     },
  //     data:{
  //         "urlCode" : `${window.location.href.slice(22,window.location.href.length)}`,
  //     }
  //   };

  // axios(config)
  // .then((res)=>{
  //     window.location.replace(res.data.url)
  //     console.log("logout",res.data)


  // })

  React.useEffect(() => {
    console.log(window.location.href.slice(22, window.location.href.length))
    var config = {
      method: 'post',
      url:
        `${posturl}/url`,
      headers: {
        'Content-Type': 'text/plain'
      },
      data: {
        "urlCode": `${props.id}`,
      }
    };

    axios(config)
      .then((res) => {


        if (res.data.status === true) {
          window.location.assign(res.data.url)

        }
        else if (res.data.status === false) {
          setActivestatus(true)
        }

        console.log("logout", res.data)


      })

  }, [])
  return (
    <div>
      {activestatus && <Linkdeactivationpage message={"The current Url is expired"} />}
      {/* {correcturl&&<Linkdeactivationpage message={"The current URL is not added in Table"}/>} */}
    </div>
  )
}

export default Longutldisplay
