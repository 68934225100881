import React from "react";
import { Paper } from "qdm-component-library";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import axios from "axios";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import A from "../../images/1.png";
import B from "../../images/2.png";
import C from "../../images/3.png";
import D from "../../images/4.png";
import { geturl } from "../urlconst";
import Topbar from "../Topbar";
import backgroundImage from "../../images/d1.png";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    marginBottom: "19px",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 50,
    width: "100%",
    backgroundColor: "#00205C",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(-1),
    marginLeft: theme.spacing(-1.5),
    // color: 'rgba(255, 255, 255, 0.7)',
    "&.Mui-selected": {
      color: "#000000",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function Dashboard(props) {
  const [value, setValue] = React.useState(0);
  const [redis, setRedis] = useState(false);
  const [ping, setPing] = useState(false);
  const [Http, setHttp] = useState();
  const [Current, setCurrent] = useState();
  const [Expired, setExpired] = useState();
  const [metrics, setMetrics] = useState();
  const [trueorfalse, setTrueorfalse] = useState(false);
  const handleChange = (event, newValue) => {
    console.log("newvalue", newValue);
    setValue(newValue);
  };

  const Redis = () => {
    axios
      .get(`${geturl}/health/redis`)

      .then((response) => {
        console.log(response.data);
        if (response.data.status === "down") {
          setRedis(false);
        } else {
          setRedis(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Ping = () => {
    axios
      .get(`${geturl}/health/ping`)

      .then((response) => {
        console.log(response.data.status);
        if (response.data.status === "down") {
          setPing(false);
        } else {
          setPing(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  ////http

  const Serverreq = () => {
    axios
      .get(`${geturl}/metrics/http.server.requests`)

      .then((response) => {
        console.log(response.data);
        //if(response.data.status === "down")
        setHttp(response.data.measurements[value].value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  //sessions

  const Serveractivereq = () => {
    axios
      .get(`${geturl}/metrics/tomcat.sessions.active.current`)

      .then((response) => {
        console.log(response.data.measurements[value].value);
        //if(response.data.status === "down")
        setCurrent(response.data.measurements[value].value);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sessionsexp = () => {
    axios
      .get(`${geturl}/metrics/tomcat.sessions.expired`)

      .then((response) => {
        console.log("hiii", response.data.measurements[value].value);
        //if(response.data.status === "down")

        setExpired(response.data.measurements[value].value);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  ////metrics
  const minutes = (t) => {
    var minutes = t / 60;
    if (minutes > 60) {
      hours(t);
    } else {
      setMetrics(`${Math.floor(minutes)} minutes`);
    }
  };
  const hours = (t) => {
    var hours = t / (60 * 60);
    if (hours > 24) {
      days(t);
    } else {
      setMetrics(`${Math.floor(hours)} hours`);
    }
  };
  const days = (t) => {
    var days = t / (60 * 60 * 24);
    if (days > 30) {
      months(t);
    } else {
      setMetrics(`${Math.floor(days)} days`);
    }
  };
  const months = (t) => {
    var months = t / (60 * 60 * 24 * 30);
    if (months > 12) {
      years(t);
    } else {
      setMetrics(`${Math.floor(months)} months`);
    }
  };
  const years = (t) => {
    var years = t / (60 * 60 * 24 * 30);
    setMetrics(`${Math.floor(years)} years`);
  };

  const metricsuptime = () => {
    axios
      .get(`${geturl}/metrics/process.uptime`)
      .then((response) => {
        console.log(`${geturl}/metrics/process.uptime`);

        console.log(response.data);
        //if(response.data.status === "down")
        // setMetrics(response.data.measurements[value].value)
        var time = response.data.measurements[value].value;

        if (time > 60) {
          minutes(time);
        }
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const timer = setTimeout(() => {
    setTrueorfalse(!trueorfalse);
  }, 30000);

  React.useEffect(() => {
    Redis();
    Ping();
    Serverreq();
    Serveractivereq();
    sessionsexp();
    metricsuptime();
    return () => {
      clearTimeout(timer);
    };
  }, [trueorfalse]);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Paper
          style={{
            backgroundColor: "#FFFFFF",
            height: "70vh",
            width: "95vw",
            borderRadius: "20px",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "space-around",
            padding: "13px 5px 5px 5px",
          }}
        >
          <div
            style={{
              backgroundColor: "#56C38E",
              height: "80px",
              width: "285px",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "1px",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-start",
                margin: "2px",
                padding: "0 0 0 10px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "29px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "white",
                }}
              >
                Health
              </Typography>
              {/* <Health style={{margin:"2px",padding:"1px"}} /> */}
              <img
                src={A}
                alt="1.svg"
                style={{
                  height: "30px",
                  width: "23px",
                  alignSelf: "flex-start",
                }}
              />
            </span>
            <span
              style={{
                margin: "auto",
              }}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#ffffff",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                Redis{" "}
                {redis ? (
                  <ArrowUpwardIcon style={{ color: "greenyellow" }} />
                ) : (
                  <ArrowDownwardIcon style={{ color: "red" }} />
                )}
                Api{" "}
                {ping ? (
                  <ArrowUpwardIcon style={{ color: "greenyellow" }} />
                ) : (
                  <ArrowDownwardIcon style={{ color: "red" }} />
                )}
              </Typography>
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#5AA4ED",
              height: "80px",
              width: "285px",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "1px",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-start",
                margin: "2px",
                padding: "0 0 0 10px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "29px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "white",
                }}
              >
                Http Request
              </Typography>
              {/* <Https style={{alignItems:"flex-start"}} /> */}
              <img
                src={B}
                alt="2.svg"
                style={{
                  width: "30px",
                  height: "30px",
                  alignSelf: "flex-start",
                }}
              />
            </span>
            <span
              style={{
                margin: "auto",
              }}
            >
              <Typography
                style={{
                  fontSize: "12px",
                  color: "#ffffff",
                  alignItems: "right",
                }}
              >
                Total Req: {Http}
              </Typography>
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#E75252",
              height: "80px",
              width: "285px",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "1px",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-start",
                margin: "2px",
                padding: "0 0 0 10px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "29px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "white",
                }}
              >
                Sessions
              </Typography>
              {/* <Sessions style={{}} /> */}
              <img
                src={C}
                alt="3.svg"
                style={{ width: "30px", height: "35px" }}
              />
            </span>
            <span
              style={{
                margin: "auto",
              }}
            >
              <Typography
                style={{
                  color: "#ffffff",
                  display: "flex",
                  flexDirection: "column",
                  fontSize: "12px",
                }}
              >
                <span> Current Sessions: {Current} </span>
                <span> Expired Sessions: {Expired}</span>
              </Typography>
            </span>
          </div>
          <div
            style={{
              backgroundColor: "#F1A53D",
              height: "80px",
              width: "285px",
              borderRadius: "10px",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              flexWrap: "wrap",
              alignItems: "center",
              padding: "1px",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignSelf: "flex-start",
                margin: "2px",
                padding: "0 0 0 10px",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Lato",
                  fontSize: "20px",
                  fontStyle: "normal",
                  fontWeight: "300",
                  lineHeight: "29px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  color: "white",
                }}
              >
                Metrics
              </Typography>
              {/* <Metrics style={{}} /> */}
              <img
                src={D}
                alt="4.svg"
                style={{ width: "28px", height: "30px" }}
              />
            </span>
            <span
              style={{
                margin: "auto",
              }}
            >
              <Typography style={{ fontSize: "12px", color: "#ffffff" }}>
                Process Up time {metrics}
              </Typography>
            </span>
          </div>
        </Paper>
      </div>
    </div>
  );
}
