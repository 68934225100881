import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import FormControlLabel from "@mui/material/FormControlLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { SvgIcon, TableHead } from "@mui/material";
import Buttone from "@mui/material/Button";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import axios from "axios";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Dialog from "@mui/material/Dialog";
import { dbname, arangodbservice, posturl, geturl } from "../urlconst";
import Delete from "../Dailogs/Delete";
import Copy from "../Dailogs/Copy";
import Add from "../Dailogs/Add";
import Edit from "../Dailogs/Edit";
import Alerts from "../Alerts";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import { withStyles } from "@material-ui/core/styles";
const BootstrapInputu = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(1.5),
  },
  "& .MuiInputBase-input": {
    borderRadius: 5,
    backgroundColor: "#E8E9EA",
    border: "1px solid #cccaca",
    fontSize: 12,
    height: 6,
    width: 160,
    fontFamily: "Arial",
    padding: "10px 12px",
    zIndex: "0",

    // Use the system font instead of the default Arial font.
    fontFamily: ["Arial"].join(","),
  },
}));

const StyledTableRow = withStyles((theme) => ({
  root: {
    height: 10,
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "0px 20px",
  },
}))(TableCell);

function Eye() {
  return (
    <SvgIcon>
      <svg
        width="18"
        height="10"
        viewBox="0 0 20 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.912 7.9224C2.7304 3.9924 6.1516 1.2 10 1.2C13.8472 1.2 17.2684 3.9924 18.088 7.9224C18.1205 8.07835 18.2135 8.21501 18.3468 8.30233C18.48 8.38965 18.6424 8.42046 18.7984 8.388C18.9543 8.35554 19.091 8.26246 19.1783 8.12923C19.2656 7.996 19.2965 7.83355 19.264 7.6776C18.3316 3.2076 14.4316 0 10 0C5.5684 0 1.6684 3.2076 0.735996 7.6776C0.703533 7.83355 0.73435 7.996 0.821667 8.12923C0.908984 8.26246 1.04565 8.35554 1.2016 8.388C1.35754 8.42046 1.52 8.38965 1.65322 8.30233C1.78645 8.21501 1.87953 8.07835 1.912 7.9224Z"
          fill="#6F6C6C"
        />
        <path
          d="M9.98809 3.60001C11.102 3.60001 12.1703 4.04251 12.9579 4.83016C13.7456 5.61781 14.1881 6.6861 14.1881 7.80001C14.1881 8.91392 13.7456 9.9822 12.9579 10.7699C12.1703 11.5575 11.102 12 9.98809 12C8.87418 12 7.80589 11.5575 7.01824 10.7699C6.23058 9.9822 5.78809 8.91392 5.78809 7.80001C5.78809 6.6861 6.23058 5.61781 7.01824 4.83016C7.80589 4.04251 8.87418 3.60001 9.98809 3.60001Z"
          fill="#6F6C6C"
        />
      </svg>
    </SvgIcon>
  );
}

export default function Tablemiui2() {
  const [deletedailog, setDeletedailog] = React.useState(false);
  const [deletealldailog, setDeletealldailog] = React.useState(false);
  const [editdialog, setEditialog] = React.useState(false);
  const [viewdialog, setViewialog] = React.useState(false);
  const [adddialog, setadddialog] = React.useState(false);
  const [getfiles, setGetfiles] = React.useState([]);
  const [filterdata, setFilterdata] = React.useState([]);
  const [viewinput, setViewinput] = React.useState();
  const [deleteid, setDeleteid] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);
  const [editvalue, setEditvalue] = React.useState();
  const [selected, setSelected] = React.useState([]);
  const [alert, setAlert] = React.useState({
    alertopen: "",
    alertvalue: "",
    alertseverity: "",
  });

  React.useLayoutEffect(() => {
    console.log(dbname, arangodbservice, posturl, geturl);
    getData();
  }, [alert.alertopen]);

  const getData = () => {
    axios
      .post(`${arangodbservice}/read_documents`, {
        db_name: dbname,
        entity: "shortUrls",
        filter: `shortUrls.activestatus == true && shortUrls.clientId == '${sessionStorage.getItem(
          "clientid"
        )}' && shortUrls.projectid == '${sessionStorage.getItem("projectid")}'`,
        sort: "shortUrls.updatedate desc",
        return_fields: "shortUrls",
      })
      .then((response) => {
        console.log(response.data.result);
        // let clonedata = response.data.result.map((obj) => ({
        //   ...obj,
        //   isStatus: obj.status,
        // }));

        setGetfiles(response.data.result);
        // console.log("getdata", clonedata);
      });
    console.log(arangodbservice);
  };

  /////////////////////PAGINATION///////////////

  const handleChangePage = (event, newPage) => {
    console.log(newPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  /////////////////////POPUP FUNC////////////////////////////

  const opendeletefunc = (v) => {
    setDeleteid(v._id);
    setDeletedailog(true);
  };

  const openViewFunc = (v) => {
    console.log(v);
    setViewinput(v.shortenUrl);
    console.log(viewinput);

    setViewialog(!viewdialog);
  };

  const openaddfunc = (v) => {
    setadddialog(true);
  };
  //////////////////////POPUP open,close FUNC/////////////////

  const closeaddFunc = (t) => {
    if (t === "success") {
      setAlert({
        alertopen: true,
        alertvalue: "Added Successfully",
        alertseverity: "success",
      });
    } else if (t === "error") {
      setAlert({
        alertopen: true,
        alertvalue: "Oops ! Something went wrong",
        alertseverity: "error",
      });
    }
    setadddialog(false);
  };

  const closeViewFunc = () => {
    setViewialog(false);
  };
  const closeEditFunc = (t) => {
    console.log(t);
    if (t === "true") {
      setAlert({
        alertopen: true,
        alertvalue: "Edited Successfully",
        alertseverity: "success",
      });
    } else if (t === "error") {
      setAlert({
        alertopen: true,
        alertvalue: "Oops ! Something went wrong",
        alertseverity: "error",
      });
    }
    setEditialog(false);
  };
  /////////////////////// ADD ,DELETE AND SEARCH FUCN//////////////
  const handleSearchTable = (v) => {
    var temp = v.target.value.toLowerCase();
    if (temp !== "") {
      setPage(0);
      // var temp =v.target.value.replace(/['"]+/g, '')

      // const serachdata = getfiles.filter((n)=>{
      //   return       n.url?.toLowerCase().includes(temp.toString()?.toLowerCase()) || !isNaN(n.shortenUrl)?n.shortenUrl.toLowerCase().includes(temp.toString().toLowerCase()):n.shortenUrl.toLowerCase().includes(toString().toLowerCase().temp)  || isNaN(n.clientKey)?n.clientKey.toLowerCase().includes(temp.toString().toLowerCase()):n.clientKey.toLowerCase().includes(temp.toLowerCase())

      //   // n.orgId.toLowerCase().includes(temp.toString().toLowerCase()) ||
      // })
      // setFilterdata(serachdata)
      // console.log(serachdata)

      var config = {
        method: "post",
        url: `${arangodbservice}/read_documents`,
        headers: {
          "Content-Type": "application/json",
        },

        data: {
          db_name: dbname,
          entity: "shortUrls",
          filter: `shortUrls.activestatus == true && shortUrls.clientId == '${sessionStorage.getItem(
            "clientid"
          )}' && shortUrls.projectid == '${sessionStorage.getItem(
            "projectid"
          )}' && (LOWER(shortUrls.shortenUrl) LIKE '%${temp}%' || LOWER(shortUrls.url) LIKE '%${temp}%' ||  LOWER(shortUrls.clientKey) LIKE '%${temp}%' ||  LOWER(shortUrls.orgId) LIKE '%${temp}%' )`,
          sort: "shortUrls.updatedate desc",
          return_fields: "shortUrls",
        },
      };

      axios(config)
        .then((response) => {
          console.log(response.data.result);
          setGetfiles(response.data.result);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      getData();
    }
  };

  ////////////////////POPUP FINAL BUTTON FUNC///////////////

  const handleDeleteAll = () => {
    axios
      .post(`${arangodbservice}/delete_document`, {
        db_name: dbname,
        entity: "shortUrls",
        filter: `shortUrls._key IN ${JSON.stringify(selected)}`,
      })
      .then((response) => {
        console.log("delete res", response.data);
        setSelected("");
        setDeletealldailog(false);
        setAlert({
          alertopen: true,
          alertvalue: "Deleted Successfully",
          alertseverity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          alertopen: true,
          alertvalue: "Oops ! Something wrong",
          alertseverity: "errror",
        });
      });
  };

  const deletePopupbtn = () => {
    console.log("id is", deleteid);
    axios
      .post(`${arangodbservice}/soft_delete`, {
        db_name: dbname,
        entity: "shortUrls",
        filter: `shortUrls._id == '${deleteid}'`,
      })
      .then((response) => {
        console.log("delete res", response.data);
        // console.log(deleteid, c);
        setDeletedailog(false);
        setAlert({
          alertopen: true,
          alertvalue: "Deleted Successfully",
          alertseverity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          alertopen: true,
          alertvalue: "Oops ! Something wrong",
          alertseverity: "errror",
        });
      });
  };

  const StatusUpdate = (v) => {
    var config = {
      method: "post",
      url: `${arangodbservice}/upsert_document`,
      headers: {
        "Content-Type": "application/json",
      },
      data: [
        {
          db_name: dbname,
          entity: "shortUrls",
          filter: { _key: v._key },
          doc: {
            status: !v.status,
          },
        },
      ],
    };

    axios(config)
      .then((response) => {
        console.log("edit status", response.data);
        setAlert({
          alertopen: true,
          alertvalue: "Status updated Successfully",
          alertseverity: "success",
        });
      })
      .catch((err) => {
        console.log(err);
        setAlert({
          alertopen: true,
          alertvalue: "Oops ! Something wrong",
          alertseverity: "errror",
        });
      });
  };

  //////////////////////CHECKBOX//////////////

  const handleClick = (event, _key) => {
    const selectedIndex = selected.indexOf(_key);
    console.log("si", selectedIndex);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);

    console.log(newSelected.slice(1, newSelected - 1));
    console.log(selected);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = getfiles.map((n) => n._key);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const alertClose = () => {
    setAlert({ ...alert, alertopen: false });
  };
  return (
    <Paper
      style={{
        backgroundColor: "white",
        width: "90vw",
        height: "auto",
        borderRadius: "15px",
      }}
    >
      <div
        style={{
          width: "95%",
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "5px",
          }}
        >
          <Typography style={{ fontWeight: "700", fontFamily: "Arial" }}>
            Leader board
          </Typography>
          <span>
            <BootstrapInputu
              style={{
                height: "30px",
                fontFamily: "Arial",
                margin: "3px 5px",
              }}
              placeholder="Search"
              id="bootstrap-input"
              onChange={(e) => handleSearchTable(e)}
            />
            <Buttone
              disableElevation
              style={{
                backgroundColor: "#00205C",
                color: "white",
                width: "70px",
                height: "29px",
                borderRadius: "20px",
                margin: "3px 5px",
                zIndex: "0",
              }}
              onClick={openaddfunc}
            >
              <Typography
                style={{
                  fontamily: "Arial",
                  fontFamily: "Arial",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "400",
                  lineHeight: "22px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  textTransform: " capitalize",
                }}
              >
                + Add
              </Typography>
            </Buttone>

            <Buttone
              variant="contained"
              onClick={() => {
                setDeletealldailog(true);
              }}
              startIcon={<DeleteIcon />}
              style={{
                backgroundColor: "#ED0000",
                color: "white",
                width: "130px",
                height: "29px",
                borderRadius: "5px",
                margin: "3px 5px",

                display: selected.length > 1 ? "" : "none",
              }}
            >
              <Typography
                style={{
                  fontFamily: "Arial",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: "600",
                  lineHeight: "22px",
                  letterSpacing: "0em",
                  textAlign: "center",
                  textTransform: " capitalize",
                }}
              >
                Delete all
              </Typography>
            </Buttone>
          </span>
        </div>
        {/* <Grid
        style={{
          // width: "1195px",
        }}
      > */}
        <div
          style={{
            width: "100%",
            overflow: "hidden",
            borderRadius: "20px",
          }}
        >
          <TableContainer
            style={{
              borderRadius: "20px",
              borderBottom: "1.5px solid grey ",
              borderLeft: "1.5px solid grey ",
              // borderRight: "1.5px solid grey ",
            }}
            sx={{ height: 300 }}
          >
            <Table
              stickyHeader={true}
              style={{ borderRadius: "10px", backgroundColor: "white" }}
              size="small"
            >
              <TableHead>
                <StyledTableRow style={{}}>
                  <StyledTableCell
                    style={{
                      borderBottom: "0px",
                      backgroundColor: "#00205C",
                      height: "33px",
                    }}
                  >
                    <FormControlLabel
                      style={{
                        padding: "0px",
                      }}
                      label=""
                      control={
                        <Checkbox
                          size="medium"
                          style={{ color: "white" }}
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < getfiles.length
                          }
                          checked={
                            getfiles.length > 0 &&
                            selected.length === getfiles.length
                          }
                          onChange={handleSelectAllClick}
                        />
                      }
                    />
                  </StyledTableCell>
                  {/* <StyledTableCell
                    style={{
                      color: "white",
                      fontFamily: "Arial",
                      backgroundColor: "#00205C",
                      height: "33px"
                    }}
                  >
                    S.No
                  </StyledTableCell> */}
                  <StyledTableCell
                    style={{
                      color: "white",
                      fontFamily: "Arial",
                      backgroundColor: "#00205C",
                      height: "33px",
                    }}
                  >
                    Long URL
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: "white",
                      fontFamily: "Arial",
                      backgroundColor: "#00205C",
                      height: "33px",
                    }}
                  >
                    Short URL
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      fontFamily: "Arial",
                      height: "33px",
                    }}
                  >
                    Client Key
                  </StyledTableCell>
                  {/* <StyledTableCell
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      fontFamily: "Arial",
                      height: "33px"
                    }}
                  >
                    Project ID
                  </StyledTableCell> */}
                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      fontFamily: "Arial",
                      height: "33px",
                    }}
                  >
                    Validity
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      fontFamily: "Arial",
                      fontFamily: "Arial",
                      height: "33px",
                    }}
                  >
                    Status
                  </StyledTableCell>
                  <StyledTableCell
                    style={{
                      color: "white",
                      backgroundColor: "#00205C",
                      borderBottom: "0px",
                      fontFamily: "Arial",
                      height: "33px",
                      textAlign: "center",
                    }}
                  >
                    Action
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {getfiles
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((v) => {
                    const isItemSelected = isSelected(v._key);

                    return (
                      <>
                        <StyledTableRow
                          hover
                          key={v.sNo}
                          selected={isItemSelected}
                        >
                          <StyledTableCell
                            padding="checkbox"
                            style={{ height: "33px" }}
                          >
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              onClick={(event) => handleClick(event, v._key)}
                            />
                          </StyledTableCell>
                          {/* <StyledTableCell align="Left" style={{ height: "33px" }} >{v?.id}</StyledTableCell> */}
                          <StyledTableCell>
                            <div
                              style={{
                                display: "block",
                                width: "200px",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {v?.url}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell style={{ height: "33px" }}>
                            <div
                              style={{
                                display: "block",
                                width: "200px",

                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {v?.shortenUrl}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ height: "33px" }}
                          >
                            {v?.clientKey}
                          </StyledTableCell>
                          {/* <StyledTableCell align="center" style={{ height: "33px" }}>{v?.orgId}</StyledTableCell> */}
                          <StyledTableCell
                            align="center"
                            style={{ height: "33px" }}
                          >
                            {v?.ttl}
                          </StyledTableCell>
                          <StyledTableCell
                            align="center"
                            style={{ height: "33px" }}
                          >
                            <IconButton
                              aria-label="expand row"
                              size="small"
                              onClick={() => {
                                StatusUpdate(v);
                              }}
                            >
                              {v.status ? (
                                <ToggleOnIcon style={{ color: "#09AD06" }} />
                              ) : (
                                <ToggleOffIcon />
                              )}
                            </IconButton>
                          </StyledTableCell>
                          <StyledTableCell padding="none" align="center">
                            <div
                              style={{
                                display: "inline-flex",
                                flexDirection: "row",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  openViewFunc(v);
                                }}
                                align="center"
                              >
                                <VisibilitySharpIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  setEditialog(true);
                                  setEditvalue(v);
                                }}
                              >
                                <ModeEditOutlineOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    color: " #0BC4FF",
                                  }}
                                />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  opendeletefunc(v);
                                }}
                              >
                                <DeleteIcon
                                  fontSize="small"
                                  style={{
                                    color: "#FF1414",
                                  }}
                                />
                              </IconButton>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      </>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 7, 10, 20, 50, 100, 250, 500, 1000]}
            component="div"
            count={getfiles.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ fontFamily: "Arial" }}
            padding="false"
          />
        </div>
      </div>
      <Dialog
        onClose={closeEditFunc}
        open={editdialog}
        style={{
          color: "rgba(255, 255, 255, 1)",
        }}
      >
        <Edit onClose={closeEditFunc} v={editvalue} />
      </Dialog>
      <Dialog
        onClose={closeViewFunc}
        open={viewdialog}
        style={{
          borderRadius: "20px",
        }}
      >
        <Copy text={viewinput} onClick={closeViewFunc} />
      </Dialog>
      <Dialog onClose={closeaddFunc} open={adddialog}>
        <Add onClose={closeaddFunc} />
      </Dialog>
      <Dialog
        onClose={() => {
          setDeletedailog(false);
        }}
        open={deletedailog}
      >
        <Delete
          onClose={() => {
            setDeletedailog(false);
          }}
          deletePopupbtn={deletePopupbtn}
        />
      </Dialog>
      <Dialog
        onClose={() => {
          setDeletealldailog(false);
        }}
        open={deletealldailog}
      >
        <Delete
          onclose={() => {
            setDeletealldailog(false);
          }}
          handleDeleteAll={handleDeleteAll}
          true={true}
        />
      </Dialog>
      <Alerts
        open={alert.alertopen}
        // onClose={alert.onClose}
        alertvalue={alert.alertvalue}
        severity={alert.alertseverity}
        onClose={alertClose}
      />
    </Paper>
  );
}
