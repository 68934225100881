import React from "react";
import backgroundImage from "../../images/d1.png";
import { BackgroundImage, Paper, TextInput } from "qdm-component-library";
import Typography from "@mui/material/Typography";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Container, IconButton } from "@mui/material";
import axios from "axios";
import InputLabel from "@mui/material/InputLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Button from "@mui/material/Button";
import { geturl, posturl } from "../urlconst";
import Topbar from "../Topbar";
import Alerts from "../Alerts";
import Appbar from "../../Appbar";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  "& .MuiInputBase-input": {
    borderRadius: "5px 0px 0px 5px",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #00205C",
    fontSize: 14,
    color: "white",
    backgroundColor: "#00205C",
    padding: "5px 25px 5px 10px",
    height: "10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Arial font.
    fontFamily: ["-apple-system"].join(","),
    "&:focus": {
      borderRadius: "5px 0px 0px 5px",
      borderColor: "#00205C",
      color: "white",
    },
  },
}));

const BootstrapInputt = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: "0px 5px 5px 0px",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontFamily: "Arial",
    fontSize: 14,
    padding: "5px 25px 8px 10px",
    height: "10px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Arial font.
    fontFamily: ["Arial"].join(","),
    "&:focus": {
      borderRadius: "0px 5px 5px 0px",
      borderColor: "#80bdff",
    },
  },
}));

export default function Logger(props) {
  React.useEffect(()=>{
console.log("routes",props)
  },[])
  const [value, setValue] = React.useState();
  const [getpost, setAGetpost] = React.useState("");
  const [valueforgp, setValueforgp] = React.useState("");
  const [url, setUrl] = React.useState("");

  const [clientid, setClientid] = React.useState("");

  const [clientkey, setClientKey] = React.useState("");

  const [orgid, setOrgid] = React.useState("");

  const [domainForShortUrl, setDomainForShortUrl] = React.useState("");

  const [ttl, setTtl] = React.useState("");

  const [prefttl, setPrefttl] = React.useState("");

  const [ttltypes, setTtltypes] = React.useState("");
  const [responsebody, setResponsebody] = React.useState([]);
  const [requestbody, setRequestbody] = React.useState([]);
  const [link, setLink] = React.useState();
  const [errormsg, setErrormsg] = React.useState(false);
  const [successmsg, setSuccessmsg] = React.useState(false);

  const handleChange = (event) => {
    setValueforgp(event.target.value);
    setResponsebody([]);
    setRequestbody([]);
  };

  const handleChangeforgp = (event) => {
    setAGetpost(event.target.value);
    setResponsebody([]);
    setRequestbody([]);
  };
  const [alert, setAlert] = React.useState({
    alertopen:"",
    alertvalue:"",
    alertseverity:"",

  });
  var arrayPostLinks = [`${posturl}/shortenUrl`, `${posturl}/url`];
  var arraygetLink = [
    `${geturl}/health/redis`,

    `${geturl}/health/ping`,

    `${geturl}/metrics/http.server.requests`,
    `${geturl}/metrics/jvm.buffer.memory.used`,
    `${geturl}/metrics/process.cpu.usage`,
    `${geturl}/metrics/process.uptime`,
    `${geturl}/metrics/tomcat.sessions.active.current`,
    `${geturl}/metrics/tomcat.sessions.expired`,
  ];
  const handledropdownget = () => {
    debugger;
    setValue("Get");

    setResponsebody([]);
    setRequestbody([]);
  };

  const handledropdownpost = () => {
    setValue("Post");
    setResponsebody([]);
    setRequestbody([]);
  };

  var forshort = {
    url: `${url}`,

    clientId: Number(clientid),

    clientKey: `${clientkey}`,

    orgId: Number(orgid),

    domainForShortUrl: `${domainForShortUrl}`,

    ttl: Number(ttl),

    PreferredTtlType: `${prefttl}`,

    ttlTypes: [`${ttltypes}`],
  };
  var forlong = {
    urlCode: `${url}`,

    // clientId: Number(clientid),

    // clientKey: `${clientkey}`,

    // orgId: Number(orgid),

    // domainForShortUrl: `${domainForShortUrl}`,

    // ttl: Number(ttl),

    // PreferredTtlType: `${prefttl}`,

    // ttlTypes: [`${ttltypes}`],
  };

  const tryfunc = () => {
    console.log(value);

    if (value === "Get") {
      console.log(JSON.stringify(link));
      axios
        .get(`${link}`)
        .then((response) => {
          setAlert({alertopen:true,alertseverity:"success",alertvalue:"Data retrieved successfully"})
          console.log(response.data);
          setResponsebody([response.data]);
        })
        .catch((err) => {
          console.log(err);
          setAlert({alertopen:true,alertseverity:"error",alertvalue:"Oops ! Something went wrong"})
        });
    } else if(value === "Post"){
      var config = {
        method: "post",
        url: `${link}`,
        headers: {
          "Content-Type": "text/plain",
        },
        data: link === `${posturl}/shortenUrl` ? forshort : forlong,
      };
      setRequestbody([config.data]);
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          setAlert({alertopen:true,alertseverity:"success",alertvalue:"Data Posted successfully"})
          setResponsebody([response.data]);
          setSuccessmsg(true);
          setUrl("");
          setClientid("");
          setClientKey("");
          setDomainForShortUrl("");
          setPrefttl("");
          setTtltypes("");
          setTtl("");
          setOrgid("");
          console.log(responsebody);
        })
        .catch(function (error) {
          console.log(error);
          setAlert({alertopen:true,alertseverity:"error",alertvalue:"Oops ! Something went wrong"})

        });
    }
  };
  const handlemenuitem = (a) => {
    setLink(a);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

  setAlert({...alert,alertopen:false})
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          height: "calc(100vh - 0px)",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
          backgroundRepeatX: "no-repeat",
          backgroundRepeatY: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          display:"flex",
          flexDirection:"column",
          alignItems:"center"
        }}
      >
        <span
        style={{width:"100%"}}>
        <Topbar />
        </span>
        <Paper
          style={{
            backgroundColor: "#FFFFFF",
            height: "80vh",
            width: "90vw",
            borderRadius: "20px",
            overflowY: "scroll",
            scrollbarColor: "rebeccapurple green",
            scrollbarWidth: "thin",
            padding: "10px ",
            // marginLeft:"50px"
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems:"center",
              padding: "3px",
            }}
          >
            <IconButton
              onClick={() => props.history.goBack()}
              style={{
              }}
           >
              <KeyboardBackspaceOutlinedIcon
                style={{
                  color: "black",
                  height: "auto",
                }}
              />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              color="#oooooo"
              gutterBottom
              style={{
                fontFamily: "Arial",
                marginTop:"auto"
              }}
            >
              API Docs
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              // alignItems: "center",
            }}
          >
            <div
              style={{
                display: "inline-flex",
                flexDirection: "row",
                // flexWrap: "wrap",
                alignItems: "flex-start",
                marginLeft: "15px",
              }}
            >
              <FormControl sx={{ width: 100, padding: 0 }}>
                <Select
                  displayEmpty
                  value={getpost}
                  onChange={(event) => {
                    handleChangeforgp(event);
                  }}
                  // input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Select</em>;
                    }
                    return selected;
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                  style={{
                    backgroundColor: "#00205C",
                    color: "white",
                    height: "40px",
                  }}
                  input={<BootstrapInput />}
                >
                  <MenuItem disabled value="">
                    <em>Select</em>
                  </MenuItem>
                  <MenuItem value={"Get"} onClick={handledropdownget}>
                    Get
                  </MenuItem>
                  <MenuItem value={"Post"} onClick={handledropdownpost}>
                    Post
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl
                style={{
                  borderColor: "E1E1E1C",
                  borderRadius: "0px 15px 15px 0px",
                  fontSize: "10px",
                  height: "10px",
                  width: "auto",
                  minWidth: "78vw",
                  align: "center",
                  variant: "standard",
                  marginTop: "0.2em",
                }}
              >
                <Select
                  value={valueforgp}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  displayEmpty
                  input={<BootstrapInputt />}
                >
                  {getpost !== "" &&
                    getpost !== "select" &&
                    (getpost === "Get" ? arraygetLink : arrayPostLinks).map(
                      (a) => {
                        return (
                          <MenuItem
                            value={a}
                            onClick={() => {
                              handlemenuitem(a);
                            }}
                            style={{ fontSize: "12px" }}
                          >
                            {a}
                          </MenuItem>
                        );
                      }
                    )}
                </Select>
              </FormControl>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent:"space-between",
                margin: "3px",
                // alignSelf: "flex-start",
              }}
            >
              <TextareaAutosize
                placeholder=" Request body :"
                style={{
                  height: "300px",
                  // width: "750px",
                  minWidth: "60vw",
                  borderRadius: "5px",
                  borderColor: "black",
                  fontFamily: "Lato",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "12px",
                  lineHeight: "14px",
                  margin: "5px 5px 5px 15px",
                }}
                value={requestbody.map((e) => JSON.stringify(e, null, 10))}
              ></TextareaAutosize>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {value === "Post" ? (
                  <>
                    <TextInput
                      placeholder="URL"
                      style={{
                        borderColor: "E1E1E1C",
                        borderRadius: "5px",
                        fontSize: "10px",
                        height: "12px",
                        width: "300px",
                        fontFamily: "Arial",
                        align: "center",
                        margin: "5px",
                      }}
                      value={url}
                      hoverColor="#0071F2"
                      onChange={(e) => setUrl(e.target.value)}
                      maxLength="1000"
                    />
                    {link===`${posturl}/shortenUrl`&&
                    <>
                    <TextInput
                      placeholder="client Id"
                      type="Number"
                      style={{
                        borderColor: "E1E1E1C",
                        borderRadius: "5px",
                        fontSize: "10px",
                        height: "25px",
                        width: "300px",
                        fontFamily: "Arial",
                        align: "center",
                        margin: "5px",
                      }}
                      hoverColor="#0071F2"
                      value={clientid}
                      onChange={(e) => setClientid(e.target.value)}
                    />
                    <TextInput
                      placeholder="Client key"
                      style={{
                        borderColor: "E1E1E1C",
                        borderRadius: "5px",
                        fontSize: "10px",
                        height: "25px",
                        fontFamily: "Arial",
                        width: "300px",
                        align: "center",
                        margin: "5px",
                      }}
                      hoverColor="#0071F2"
                      value={clientkey}
                      required
                      onChange={(e) => setClientKey(e.target.value)}
                    />
                    <TextInput
                      type="Number"
                      placeholder="Org Id"
                      style={{
                        borderColor: "E1E1E1C",
                        marginTop: "10px",
                        borderRadius: "5px",
                        fontSize: "10px",
                        height: "25px",
                        fontFamily: "Arial",
                        width: "300px",
                        align: "center",
                        margin: "5px",
                      }}
                      hoverColor="#0071F2"
                      value={orgid}
                      onChange={(e) => setOrgid(e.target.value)}
                    />
                    <TextInput
                      placeholder="DomainForShortUrl"
                      style={{
                        borderColor: "E1E1E1C",
                        borderRadius: "5px",
                        fontSize: "10px",
                        height: "25px",
                        fontFamily: "Arial",
                        width: "300px",
                        align: "center",
                        margin: "5px",
                      }}
                      hoverColor="#0071F2"
                      value={domainForShortUrl}
                      onChange={(e) => setDomainForShortUrl(e.target.value)}
                    />
                    <TextInput
                      placeholder="ttl"
                      style={{
                        borderColor: "E1E1E1C",
                        borderRadius: "5px",
                        fontSize: "10px",
                        height: "25px",
                        fontFamily: "Arial",
                        width: "300px",
                        align: "center",
                        margin: "5px",
                      }}
                      hoverColor="#0071F2"
                      value={ttl}
                      onChange={(e) => setTtl(e.target.value)}
                    />
                    <FormControl
                      style={{ margin: "8px 5px 5px 2px", padding:"3px" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        style={{
                          color: "grey",
                          fontSize: "11px",
                          fontFamily: "Arial",
                          hieght: "5px",
                          // position:"relative",
                          marginTop:"-3px"
                        }}
                      >
                        {" "}
                        Ttl types
                      </InputLabel>

                      <Select
                        placeholder="Preferred ttl"
                        labelId="demo-simple-select-label"
                        style={{
                          borderColor: "E1E1E1C",
                          borderRadius: "5px",
                          fontSize: "10px",
                          height: "25px",
                          width: "300px",
                          align: "center",
                        }}
                        hoverColor="#0071F2"
                        value={prefttl}
                        onChange={(e) => setPrefttl(e.target.value)}
                      >
                        <MenuItem value={""}>select</MenuItem>
                        <MenuItem value={"Minutes"}>minutes</MenuItem>
                        <MenuItem value={"hours"}>hours</MenuItem>
                        <MenuItem value={"Days"}>Days</MenuItem>
                        <MenuItem value={"Months"}>Months</MenuItem>
                        <MenuItem value={"Years"}>years</MenuItem>
                      </Select>
                    </FormControl>
                    </>
                    }
                    <Button
                      style={{
                        borderRadius: "15px",
                        backgroundColor: "#00205C",
                        width: "80px",
                        height: "23px",
                      }}
                      onClick={tryfunc}
                    >
                      <Typography
                        style={{
                          color: "white",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "600",
                          lineHeight: "22px",
                          letterSpacing: "0.15em",
                          textAlign: "center",
                          textTransform: " capitalize",
                        }}
                      >
                        Try
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <Button
                    style={{
                      borderRadius: "15px",
                      backgroundColor: "#00205C",
                      width: "80px",
                      height: "23px",
                      marginRight:"100px",
                      marginLeft:"auto"
                      
                    }}
                    onClick={tryfunc}
                  >
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: "600",
                        lineHeight: "22px",
                        letterSpacing: "0.15em",
                        textAlign: "center",
                        textTransform: " capitalize",
                      }}
                    >
                      Try
                    </Typography>
                  </Button>
                )}
              </div>
            </div>
            <TextareaAutosize
              placeholder=" Response body :"
              style={{
                height: "300px",
                // width: "750px",
                minWidth: "60vw",
                borderRadius: "5px",
                borderColor: "black",
                fontFamily: "Lato",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "12px",
                lineHeight: "14px",
                textAlign: "left",
                margin: "5px 5px 5px 15px",
                alignSelf: "flex-start",
              }}
              value={responsebody.map((e) => JSON.stringify(e, null, 10))}
            ></TextareaAutosize>
          </div>
        </Paper>
      </div>

     
       
        <Alerts open={alert.alertopen} onClose={handleClose} alertvalue={alert.alertvalue} severity={alert.alertseverity} onClose={handleClose}/>
    </div>
  );
}
