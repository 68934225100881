import React from 'react'

import Trashcan from "../../images/Trashcav.png";

import { H6 } from "qdm-component-library";
import {  Button } from "qdm-component-library";

import Stack from "@mui/material/Stack";


function Delete(props) {


    return (
        <div>
            
<Stack direction="column" spacing={1} align="center">
          <img
            src={Trashcan}
            alt="Trashcan.png"
            style={{
              width: "60px",
              height: "75px",
              marginLeft: "100px",
              marginTop: "30px",
            }}
          />
          <H6
            name="Are you Sure ?"
            style={{
              fontSize: "29px",
              fontWeight: "500",
              width: "200px",
              marginLeft: "35px",
              marginBottom: "10px",
              marginTop: "20px",
            }}
          />
          <H6
            name={props?.true ? "Do you really want to delete these Record?":"Do you really want to delete this Record?"}
            style={{
              fontSize: "12px",
              width: "256px",
              marginBottom: "7px",
              fontfamily: "Arial",
              marginLeft: "5px",
            }}
            id={"signIn-H6-Sign-in-registered"}
          />
          <Stack direction="row" spacing={6}>
            <Button
              onClick={props.onClose || props.onclose}
              variant="contained"
              color="white"
              style={{
                fontSize: "14px",
                backgroundColor: "#C4C4C4",
                width: "100px",
                borderRadius: "10px",
                borderColor: "#C4C4C4",
                marginTop: "20px",
                marginBottom: "30px",
                marginLeft: "20px",
                marginRight: "40px",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={props.deletePopupbtn || props.handleDeleteAll}
              variant="contained"
              color="white"
              style={{
                backgroundColor: "#FF1414",
                fontSize: "14px",
                width: "100px",
                borderRadius: "10px",
                borderColor: "#FF1414",
                marginTop: "20px",
                marginBottom: "30px",
                marginRight: "20px",
                marginLeft: "-15px",
              }}
            >
              Delete
            </Button>
          </Stack>
        </Stack>
        </div>
    )
}

export default Delete
