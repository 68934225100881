import React from "react";

import Paper from "@mui/material/Paper";

import IconButton from "@mui/material/IconButton";

import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import axios from "axios";
import NativeSelect from "@mui/material/NativeSelect";

import { CgClose } from "react-icons/cg";

import { TextInput, Button } from "qdm-component-library";

import Stack from "@mui/material/Stack";

import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import { posturl } from "../urlconst";
import InputBase from "@mui/material/InputBase";

import { styled } from "@mui/material/styles";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
    "label + &": {
      marginTop: theme.spacing(3),
    },
    "& .MuiInputBase-input": {
      position: "relative",
      borderRadius: "0px 5px 5px 0px",
      height: "14px",
      lineHeight: "10px",
      border: "1px solid #cccaca ",
      textAlign: "Right",
      fontSize: 10,
      fontFamily: ["Arial"].join(","),
      "&:focus": {
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0rem rgba(0,123,255,.25)",
      },
    },
  }));

function Edit({v,...props}) {
    console.log(v,props)
    const [longURLforEdit, setLongURLforEdit] = React.useState();
    const [clientKeyforEdit, setClientKeyforEdit] = React.useState();
    const [projectidforEdit, setProjectidforEdit] = React.useState(sessionStorage.getItem("projectname"));
    const [clientIdforEdit, setClientIdforEdit] = React.useState(sessionStorage.getItem("clientname"));
    const [domainforShortutlforEdit, setDomainForShortUrlforEdit] =
      React.useState(`${window.location.origin}/url`);
    const [validityforEdit, setValidityforEdit] = React.useState();
    const [statusForEdit, setStatusForEdit] = React.useState(false);
    const [ttlTypesforedit, setTtlTypesforedit] = React.useState("");
    const [editid, setEditid] = React.useState();

    React.useEffect(() => {
        console.log("edit");
        setEditid(v._key);
        console.log(editid);
        let { url, clientKey, ttl, status } = v;

        setLongURLforEdit(url);

        setClientKeyforEdit(clientKey);

        setValidityforEdit(ttl);

        setStatusForEdit(status);

        setTtlTypesforedit(v.ttlTypes);
        setProjectidforEdit(v.orgId);
        setClientIdforEdit(v.clientId);
    },[])

   

    const editPopupbtn = (e) => {
        e.preventDefault();
        var config = {
            method: "post",
            url: `${posturl}/shortenUrl`,
            headers: {
                "Content-Type": "application/json",
            },

            data: {
                _key: editid,
                status: statusForEdit,
                url: `${longURLforEdit}`,
                clientKey: `${clientKeyforEdit}`,
                ttl: Number(validityforEdit),
                domainForShortUrl: `${domainforShortutlforEdit}`,
                clientId: sessionStorage.getItem("clientid"),
                ttlTypes: [`${ttlTypesforedit}`],
                projectid:sessionStorage.getItem("projectid")
            },
        };
        // metadataId:sessionStorage.getItem("metadataId"),
        // projectId:sessionStorage.getItem("projectid"),
        axios(config)
            .then((resp) => {
                console.log(resp.data);
                setEditid("");
        
                setLongURLforEdit("");
        
                setClientKeyforEdit("");
        
                setValidityforEdit("");
        
                setStatusForEdit("");
        
                setTtlTypesforedit("");
                setProjectidforEdit("");
                setClientIdforEdit("");
                props.onClose("true")
            })
            .catch((err) => {console.log(err)
                props.onClose("error")

            });
    };
    const handleChangeforedit = (e)=>{
        setTtlTypesforedit(e.target.value)
    }

    return (
        <div>
            <Paper>
                <form onSubmit={(e) => editPopupbtn(e)}>
                    <IconButton
                        onClick={props.onClose}

                        style={{
                            marginLeft: "488px",
                            marginBottom: "-28px",
                        }}
                    >
                        <CgClose
                            style={{
                                // margin:"0px -20px 0px 20px",
                                color: "black",
                            }}
                        />
                    </IconButton>
                    <DialogContent>
                        <Stack direction="column" spacing={2}>
                            <Stack direction="column" spacing={1}>
                                <Stack direction="row">
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        Paste your long URL here:
                                    </DialogContentText>{" "}
                                    <p style={{ color: "red", margin: "-6px 0px 0px 0px" }}>*</p>
                                    <Stack direction="row">
                                        <DialogContentText
                                            style={{
                                                marginLeft: "220px",
                                                marginTop: "-34px",
                                                fontSize: "12px",
                                                fontStyle: "Arial",
                                                fontWeight: "bold",
                                                color: "",
                                            }}
                                        >
                                            Status
                                            <IconButton
                                                onClick={() => {
                                                    setStatusForEdit(!statusForEdit);
                                                }}
                                            >
                                                {statusForEdit ? (
                                                    <ToggleOnIcon style={{ color: "#09AD06" }} />
                                                ) : (
                                                    <ToggleOffIcon />
                                                )}
                                            </IconButton>
                                        </DialogContentText>
                                    </Stack>
                                </Stack>

                                <TextInput
                                    placeholder="paste your url"
                                    isRequired={true}
                                    style={{
                                        borderColor: "#cccaca",
                                        borderRadius: "5px",
                                        height: "25px",
                                        width: "490px",
                                        align: "center",
                                        marginBottom: "10px",
                                        background: "#FFFFFF",
                                        fontFamily: "Arial",
                                        fontStyle: "normal",
                                        fontWeight: "200",
                                        fontSize: "12px",
                                        lineHeight: "17px",
                                        color: "#848181",
                                    }}
                                    required
                                    value={longURLforEdit}
                                    onChange={(e) => {
                                        setLongURLforEdit(e.target.value);
                                    }}
                                    hoverColor="#0071F2"
                                />
                               
                            </Stack>

                            <Stack direction="row" spacing={5}>
                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        Client Name
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-25px 0px 0px 69px" }}>
                                        *
                                    </p>
                                    <TextInput
                                    readOnly
                                        placeholder="client Id"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px",
                                            marginBottom: "8px",
                                            height: "25px",
                                            width: "220px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                        }}
                                        pattern="^[0-9]*$"
                                        title="special characters are not allowed"
                                        required
                                        hoverColor="#0071F2"
                                        onChange={(e) => {
                                            setClientIdforEdit(e.target.value);
                                        }}
                                        value={clientIdforEdit}
                                    />
                                   
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        DomainForShortUrl
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-26px 0px 0px 113px" }}>
                                        *
                                    </p>
                                    <TextInput
                                        readOnly
                                        defaultValue={domainforShortutlforEdit}
                                        placeholder="domainForShortUrl"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px",
                                            height: "25px",
                                            width: "230px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                        }}
                                        required
                                        hoverColor="#0071F2"
                                        onChange={(e) => {
                                          setDomainForShortUrlforEdit(e.target.value);
                                        }}
                                        value={domainforShortutlforEdit}
                                    />
                               
                                </Stack>
                            </Stack>

                            <Stack direction="row" spacing={1}>
                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        Client Key
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-25px 0px 0px 59px" }}>
                                        *
                                    </p>

                                    <TextInput
                                        placeholder="client key"
                                        type="Number"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px",
                                            height: "25px",
                                            width: "190px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                            marginTop: "-7px",
                                            marginBottom: "-14px",
                                        }}
                                        required
                                        hoverColor="#0071F2"
                                        onChange={(e) => {
                                            setClientKeyforEdit(e.target.value);
                                        }}
                                        value={clientKeyforEdit}
                                    />
                                   
                                </Stack>

                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                        }}
                                    >
                                        Project Name
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-25px 0px 0px 76px" }}>
                                        *
                                    </p>
                                    <TextInput
                                    readOnly
                                        type="text"
                                        placeholder="Numbers only(ex:123)"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px",
                                            height: "25px",
                                            width: "150px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                            marginTop: "-7px",
                                            marginBottom: "-14px",
                                        }}
                                        required
                                        hoverColor="#0071F2"
                                        onChange={(e) => {
                                            setProjectidforEdit(e.target.value);
                                        }}
                                        value={projectidforEdit}
                                    />
                                    
                                </Stack>
                                <Stack direction="column" spacing={1}>
                                    <DialogContentText
                                        style={{
                                            fontSize: "12px",
                                            fontStyle: "Arial",
                                            fontWeight: "bold",
                                            color: "black",
                                            background: "#FFFFFF",
                                        }}
                                    >
                                        Validity Period
                                    </DialogContentText>
                                    <p style={{ color: "red", margin: "-25px 0px 0px 85px" }}>
                                        *
                                    </p>
                                    <TextInput
                                        type="Number"
                                        placeholder="validity"
                                        style={{
                                            borderColor: "#cccaca",
                                            borderRadius: "5px 0px 0px 5px",
                                            height: "25.1px",
                                            width: "73px",
                                            align: "center",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "12px",
                                            lineHeight: "17px",
                                            color: "#848181",
                                        }}
                                        required
                                        hoverColor="#0071F2"
                                        onChange={(e) => {
                                            setValidityforEdit(e.target.value);
                                            console.log(validityforEdit);
                                        }}
                                        value={validityforEdit}
                                    />
                                    
                                    <NativeSelect
                                        placeholder="select"
                                        label="select"
                                        style={{
                                            display: "flex",
                                            borderColor: "#cccaca",
                                            marginTop: "-25px",
                                            borderRadius: "25px",
                                            marginLeft: "65px",
                                            width: "69px",
                                            align: "center",
                                            // boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.25)",
                                            background: "#FFFFFF",
                                            fontFamily: "Arial",
                                            fontStyle: "normal",
                                            fontWeight: "200",
                                            fontSize: "10px",
                                            lineHeight: "19px",
                                            color: "#848181",
                                            padding:"0px"
                                        }}
                                        id="demo-customized-select-native"
                                        value={ttlTypesforedit}
                                        onChange={handleChangeforedit}
                                        input={<BootstrapInput />}
                                    >
                                        
                                        <option
                                            value={"minutes"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            Minute
                                        </option>
                                        <option
                                            value={"hour"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            Hour
                                        </option>
                                        <option
                                            value={"days"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            Days
                                        </option>
                                        <option
                                            value={"months"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            Months
                                        </option>
                                        <option
                                            value={"years"}
                                            style={{
                                                color: "#00205C",
                                                fontFamily: "Arial",
                                            }}
                                        >
                                            Years
                                        </option>
                                    </NativeSelect>
                                </Stack>
                            </Stack>
                        </Stack>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            // onClick={editPopupbtn}
                            style={{
                                borderRadius: "15px",
                                marginRight: "227px",
                                marginLeft: "-40px",
                                width: "85px",
                                marginBottom: "25px",
                                marginTop: "5px",
                                height: "25px",
                                fontSize: "14px",
                                lineHeight: "10px",
                                backgroundColor: "#00205c",
                            }}
                            type="submit"
                        >
                            Shorten
                        </Button>
                    </DialogActions>
                </form>
            </Paper>
        </div>
    );
}

export default Edit;
