import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Buttone from "@mui/material/Button";
import { CgClose } from "react-icons/cg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { TextInput } from "qdm-component-library";
import Stack from "@mui/material/Stack";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { CopyToClipboard } from "react-copy-to-clipboard";

function Copy(props) {

  const [copystate, setCopyState] = React.useState(false);

  const closeViewFunc = () => {
    setCopyState(false);
  };

  return (
    <div>
      <Paper>
        <DialogContent>
          <Stack direction="column" spacing={-4}>
            <Stack direction="row" spacing={28}>
              <DialogContentText
                style={{
                  fontSize: "20px",
                  fontStyle: "Arial",
                  fontWeight: "bold",
                  textalign: "center",
                  lineHeight: "43px",
                  color: "#000000",
                }}
              >
                Your short url :
              </DialogContentText>
              <IconButton
              onClick={props.onClick || closeViewFunc}

                style={{
                  marginLeft: "280px",
                  marginTop: "",
                }}
              >
                <CgClose
                  style={{
                    color: "black",
                  }}
                />
              </IconButton>
            </Stack>
            <form style={{ display: "flex", flexDirection: "row" }}>
              <TextInput
                value={props.text}
                placeholder="Registered email"
                id="viewinput"
                style={{
                  margin: "auto",
                  marginTop: "57px",
                  marginBottom: "19px",
                  borderColor: "E1E1E1C",
                  borderRadius: "5px",
                  height: "27px",
                  width: "300px",
                  display: "flex",
                  align: "center",
                  flexGrow: "8",
                  background: "#FFFFFF",
                  fontFamily: "Arial",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "12px",
                  lineHeight: "17px",
                  color: "#848181",
                }}
                required
                hoverColor="#0071F2"
              ></TextInput>
              <CopyToClipboard
                text={props.text}
                onCopy={() => {
                  setCopyState(!copystate);
                }}
              >
                <Buttone
                  style={{
                    height: "27px",
                    width: "117px",
                    top: "57px",
                    right: "33px",
                    float: "left",
                    padding: "10px",
                    background: "#2F2E41",
                    color: "white",
                    fontSize: "10px",
                    borderLeft: "none",
                    // cursor: "pointer",
                    borderRadius: "5px",
                  }}
                  startIcon={<ContentCopyIcon />}
                  variant="contained"
                  onClick={() => {
                    //navigator.clipboard.writeText(viewinput);
                    setCopyState(!copystate);
                  }}
                >
                  copy
                </Buttone>
              </CopyToClipboard>
            </form>
          </Stack>

          {/* add popup **********************************************************************************/}
          <Typography
            style={{
              marginLeft: "310px",
              fontFamily: "Arial",
              fontStyle: "normal",
              fontSize: "16px",
              color: "#1fe600",
              lineHeight: "17px",
              margin: "5px 0px 0px 190px",
            }}
          >
            {copystate ? "Copied!" : null}
          </Typography>
        </DialogContent>
      </Paper>
    </div>
  );
}

export default Copy;
