////////////////////////////////Fallbackui.js///////////////////



import { Card, Stack, Typography } from '@mui/material';
import React, { Component } from 'react'
import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
class Fallbackui extends Component {
    constructor(props){
        super(props);
        this.state={
            err:false
        }
        this.handleback=this.handleback.bind(this)
    }

componentDidCatch(err,info){
    
console.log("error is",err)
console.log("info is",info)
this.setState({err:true})
}
handleback= ()=>{
     window.location.replace(window.location.origin)
}


    render() {
        return (
            <>

            {
                this.state.err ?
               
            
            <div style={{width:"100%",height:"100%",position:"absolute",display:"flex",backgroundColor:"white"}}>
                                <Card style={{width:"50%",height:"50%",margin:"auto",display:"flex",boxShadow:"0 0 15px white",borderRadius:"20px",backgroundColor:"rgba(206, 17, 38, 0.05)"}} >
                                    <Stack direction="row" spacing={10} alignItems="center">
                                    <ErrorIcon variant="" fontSize="large" color="error" style={{margin:"auto 0 auto 15px"}}/>
                              <Typography color="gray" style={{margin:"auto 0 auto 30px",fontSize:"50px",fontFamily:"serif",fontStyle:"normal",fontWeight:"20%"}}>500 something went wrong</Typography>
                              </Stack>
                              <Button startIcon={<ArrowBackIosIcon fontSize="large"/>} size="medium" variant="contained" color="primary" onClick={()=>this.handleback()} ><Typography>GO back</Typography></Button>
                              </Card>

            </div>
            :
            this.props.children
            }
                        </>

        )
    }
}

export default Fallbackui

