////////////////////////////////////logger.js/////////////////////////////////////

import React from "react";
import backgroundImage from "../../images/f1.png";
import Reactscrollablefeed from "react-scrollable-feed";
import { Paper } from "qdm-component-library";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import { IconButton, TextField } from "@mui/material";
import axios from "axios";
import { styled } from "@mui/system";
import Highlighter from "react-highlight-words";
import { ReactComponent as Loggers } from "../../images/Loggers.svg";
import { geturl } from "../urlconst";
import Topbar from "../Topbar";
import Appbar from "../../Appbar";
import { makeStyles } from "@material-ui/core";

const StyledInputElement = styled("input")(`
width:auto;
height: 5vh;
font-size: 1rem;
font-family: IBM Plex Sans, sans-serif;
font-weight: 400;
line-height: 1.4375em;
background: rgb(243, 246, 249);
border: 1px solid #E5E8EC;
border-radius: 20px;
padding: 6px 10px;
color: #20262D;
transition: width 300ms ease;
marginLeft:"600px"
&:hover {
  background: #EAEEF3;
  border-color: #E5E8EC;
}
&:focus {
  outline: none;
 
}
`);
const useStyles = makeStyles({
  input: "",
});
export default function Logger(props) {
  const classes = useStyles();
  const [val, setVal] = React.useState();
  const [searchvaluelog, setSearchvaluelog] = React.useState();
  const [t, setT] = React.useState(false);
  const handlesearchlogger = (e) => {
    setSearchvaluelog(e.target.value);
    console.log(searchvaluelog);
  };
  const logdata = () => {
    axios
      .get(`${geturl}/loggers`)
      .then((response) => {
        console.log(response.data);
        console.log(JSON.stringify(response.data));
        //debugger;
        setVal(JSON.stringify(response.data.loggers));
        console.log("'" + val + "'");
        console.log("..................");
      })
      .catch((err) => console.log(err));
  };

  const timer = setTimeout(() => {
    setT(!t);
  }, 300000);

  React.useEffect(() => {
    logdata();
    return () => {
      clearTimeout(timer);
    };
  }, [t]);

  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          height: "calc(100vh - 0px)",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundRepeat: "no-repeat",
          backgroundRepeatX: "no-repeat",
          backgroundRepeatY: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div>
          <span style={{}}>
            {/* <Appbar/> */}
            <Topbar />
          </span>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <div style={{}}>
              <Paper
                style={{
                  backgroundColor: "#FFFFFF",
                  height: "80vh",
                  width: "90vw",
                  borderRadius: "20px",
                  // padding: "10px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    // marginTop: "20px",
                    padding: "10px",
                    //  margin:"20px auto",
                    // border:"1px solid red"
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton onClick={() => props.history.goBack()}>
                      <KeyboardBackspaceOutlinedIcon
                        style={{
                          color: "black",
                          height: "auto",
                        }}
                      />
                    </IconButton>
                    <Loggers style={{}} />
                  </div>
                  <TextField
                    className={classes.input}
                    placeholder="Search"
                    style={{
                      fontFamily: "Arial",
                    }}
                    size={"small"}
                    onChange={(e) => handlesearchlogger(e)}
                    value={searchvaluelog}
                  />
                </div>

                <div
                  style={{
                    backgroundColor: "#ooob",
                    height: "63vh",
                    width: "87vw",
                    borderRadius: "10px",
                    border: "1px solid grey",
                    margin: "auto",
                  }}
                >
                  <Reactscrollablefeed>
                    <Highlighter
                      highlightClassName="YourHighlightClass"
                      searchWords={[`${searchvaluelog}`]}
                      autoEscape={true}
                      textToHighlight={"'" + val + "'"}
                    />
                  </Reactscrollablefeed>
                </div>
              </Paper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
