import dotenv from "dotenv";

dotenv.config();

let Config={}

export const baseurl= process.env.REACT_APP_BASE_URL



export const __keyClockLogin__ = `${process.env.REACT_APP_BASE_URL_PROTOCAL}://${process.env.REACT_APP_BASE_URL_HOST}:${process.env.REACT_APP_BASE_URL_PORT}`;



export const urls=`${__keyClockLogin__}/api/v1/orgUserSignIn`

Config.url=`${process.env.REACT_APP_BASE_URL_PROTOCAL}://${process.env.REACT_APP_BASE_URL_HOST}:${process.env.REACT_APP_BASE_URL_PORT}`;

export const dbname=process.env.REACT_APP_DB_NAME;
export const arangodbservice=process.env.REACT_APP_ARANGO_URL;
export const posturl=process.env.REACT_APP_BACKEND_POST_URL;
export const geturl=process.env.REACT_APP_BACKEND_GET_URL;

export default Config;